import React from 'react'
import { Container, Navbar, NavbarBrand} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import img from '../assets/logo.png'

const Header = ({location,name}) => {
  return (
    <Navbar expand="lg" className='bg-dark bg-opacity-75 text-light' fixed='top'>
      <Container fluid>
        <NavbarBrand href='/'>
          <img src='https://img.freepik.com/free-vector/digital-asset-nft-blockchain-technology-background_1017-41103.jpg?w=740&t=st=1703011484~exp=1703012084~hmac=fb48a50c23afcc9bf3b811b08ef44343c056396239353fa8f30915fee1b2bafc' width={ 50 } alt='' className='rounded-circle me-2' />
          <span className="text-white fw-bold text-capitalize">Defi connect</span>
        </NavbarBrand>
        <div className="d-flex">
          <div id="google_translate_element"></div>
          <Link to={`/${location}`} className='btn btn-danger text-capitalize flex-grow-1 ms-3'>
          {name}
        </Link>
        
        </div>
      </Container>
    </Navbar>
  )
}

export default Header
