import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img1 from '../../assets/claimReward.png'
import img2 from '../../assets/transaction.png'
import img3 from '../../assets/migration.png'
import img4 from '../../assets/kyc.png'
import img5 from '../../assets/sync.png'
import img6 from '../../assets/recover.png'
import img7 from '../../assets/rectify.png'
import img8 from '../../assets/auth.png'
import img9 from '../../assets/swapErr.png'
import img10 from '../../assets/stake.png'
import img11 from '../../assets/mint.png'
import img12 from '../../assets/airdrop.png'
import img13 from '../../assets/mining.png'
import img14 from '../../assets/node.png'
import Header from '../Header';

const Service = () =>
{
  const services = [
    {
      src: img1,
      text: 'claim rewards'
    },
    {
      src: img2,
      text: 'transaction error'
    },
    {
      src: img3,
      text: 'migration'
    },
    {
      src: img4,
      text: 'kyc verification'
    },
    {
      src: img5,
      text: 'synchronisation'
    },
    {
      src: img6,
      text: 'recover funds'
    },
    {
      src: img7,
      text: 'rectify'
    },
    {
      src: img8,
      text: 'authenticate'
    },
    {
      src: img9,
      text: 'swap error'
    },
    {
      src: img10,
      text: 'stake/unstake'
    },
    {
      src: img11,
      text: 'mint nft'
    },
    {
      src: img12,
      text: 'claim airdrop'
    },
    {
      src: img13,
      text: 'mining Dapp Invenstment'
    },
    {
      src: img14,
      text: 'node validator'
    },
    {
      src:'https://img.freepik.com/free-vector/computer-user-human-character-program-windows_1284-63445.jpg?w=740&t=st=1690542636~exp=1690543236~hmac=b12df3e7148b0200c7d92836f6df58aeaeffb494891938e6994c7aad18d7938f',
      text:'node error/switch'
      },
      {
      src:'https://cdn-icons-png.flaticon.com/512/399/399290.png?w=740&t=st=1690542636~exp=1690543236~hmac=81d8bc001324699808d7a2e341eaeb44c0db9fcd5e6a8b398018ce7bcf18f0e4',
      text:'revoke transaction/smart contract'
      },
      {
      src:'https://cdn.pixabay.com/photo/2018/09/05/18/21/bitcoin-3656764_1280.jpg',
      text:'CEX/DEX'
      },
      {
        src: 'https://img.freepik.com/free-vector/premium-cash-reward-bonus-work-done-best-employee-rewarding-promotion-order-efficiency-stimulation-boss-subordinate-cartoon-characters_335657-2984.jpg?w=740&t=st=1690542772~exp=1690543372~hmac=8dd577663bf448750c1b8e08f48fe986c8ad994a2606837cdbe4f1e930c4a79d',
        text: 'Crowdloan / Farming / LP / Yield'
      },
      {
        src:'https://img.freepik.com/free-photo/cryptocurrency-coding-digital-black-background-open-source-blockchain-concept_53876-124641.jpg?w=740&t=st=1690548959~exp=1690549559~hmac=377d99165838db16745c4ca60d2afd44916ada7d8933cc77381d352da4b409d0 ' ,
        text:'Pro FX / Binary Investment / Trade Option'
      },
      {
        src:'https://img.freepik.com/free-photo/3d-hand-with-safe-payment-confirmation-bill_107791-16702.jpg?size=626&ext=jpg&ga=GA1.1.1313146793.1690297481&semt=sph ',
        text:'Whitelist / Pinksale'
      }
  ]
  return (
    <>
      <Header location='user' name='connect'/>
      <div className="bg-dark text-light">
        <Container>
          <div className='py-4 mb-3'>
            <p className="h3 text-center text-capitalize">
              Our services
            </p>
            <hr className="w-25 mx-auto" />
          </div>
        <Row>
          { services.map( service => (
            <Col xs={ 10 } md={ 6 } lg={ 3 } className='p-4 text-center my-auto mx-auto'>
            <Link to='../user' className='nav-link'>
              <img className='img-fluid mx-auto rounded shadow' src={service.src} alt='' width={200} height={200} />
              <p className="h5 text-capitalize text-center">{service.text}</p>
            </Link>
          </Col>
          ))}
        </Row>
      </Container>
      </div>
    </>
  )
}

export default Service
