import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Layout from "./Layout";
import User from "./components/User";
import Service from "./components/Home/Service";

//https://interconnectdapp.com/media/walletconnect-header.png
const App=()=> {
  return (
    <Routes>
      <Route path="/" element={ <Layout/> } >
        <Route index element={ <Home /> } />
        <Route path='service' element={<Service/>}/>
        <Route path="user" element={<User/>}/>
      </Route>
    </Routes>
  );
}

export default App;
