import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Trust = () => {
  return (
    <div className='bg-dark text-light h-screen d-flex justify-content-center align-items-center py-4'>
      <div>
        <p className="h2 text-center fw-bold text-capitalize">you deserve easy access to cryptocurrencies</p>
      <p className='text-center'>Sync wallet connect is for you if you want to</p>
      <Container>
        <Row className='my-3'>
          <Col xs={ 10 } md={ 6 } lg={ 5 } className='p-2 mx-auto'>
            <div className="d-flex justify-content-evenly align-item-center border rounded p-3 shadow ">
              <BsCheckCircleFill className='my-auto text-success'/>
            <p className='my-auto fw-bold h6 '>Mine Bitcon in under 5 minutes</p>
            </div>
          </Col>
          <Col xs={ 10 } md={ 6 } lg={ 5 } className='p-2 mx-auto'>
            <div className="d-flex justify-content-evenly align-item-center border rounded p-3 shadow ">
              <BsCheckCircleFill className='my-auto text-success'/>
            <p className='my-auto fw-bold h6 '>Easily earn interest on the crypto in your wallet</p>
            </div>
          </Col>
          <Col xs={ 10 } md={ 6 } lg={ 5 } className='p-2 mx-auto'>
            <div className="d-flex justify-content-evenly align-item-center border rounded p-3 shadow ">
              <BsCheckCircleFill className='my-auto text-success'/>
            <p className='my-auto fw-bold h6 '>See your collectibles. Art & NFTs in one place</p>
            </div>
          </Col>
          <Col xs={ 10 } md={ 6 } lg={ 5 } className='p-2 mx-auto'>
            <div className="d-flex justify-content-evenly align-item-center border rounded p-3 shadow ">
              <BsCheckCircleFill className='my-auto text-success'/>
            <p className='my-auto fw-bold h6 '>Exchange your crypto without leaving the site</p>
            </div>
          </Col>
          <Col xs={ 10 } md={ 6 } lg={ 5 } className='p-2 mx-auto'>
            <div className="d-flex justify-content-evenly align-item-center border rounded p-3 shadow ">
              <BsCheckCircleFill className='my-auto text-success'/>
            <p className='my-auto fw-bold h6 '>Track charts and prices within the site</p>
            </div>
          </Col>
          <Col xs={ 10 } md={ 6 } lg={ 5 } className='p-2 mx-auto'>
            <div className="d-flex justify-content-evenly align-item-center border rounded p-3 shadow ">
              <BsCheckCircleFill className='my-auto text-success'/>
            <p className='my-auto fw-bold h6 '>Resolve any transaction issues in no time</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={2} className='mx-auto'>
            <Link className='mx-auto btn btn-danger' to='./service'>Proceed</Link>
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  )
}

export default Trust
