import React from 'react'
import Header from './Header'
import Hero from './Home/Hero';
import Trust from './Home/Trust';
import Exchange from './Home/Exchange';
import Security from './Home/Security';
import Assets from './Home/Assets';
import Testimonials from './Home/Testimonials';

const Home = () => {
  return (
    <>
      <Header location='user' name='connect'/>
      <Hero />
      <Trust/>
      <Testimonials />
      <Exchange />
      <Security />
      <Assets />
    </>
  )
}

export default Home
