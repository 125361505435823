import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Assets = () => {
  return (
    <div className='bg-dark text-light'>
      <p className="h2 fw-bold text-center text-uppercase">assets</p>
      <Container>
        <Row>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Bitcoin (BTC)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Ethereum (ETH)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Binance Coin (BNB)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Litecoin (LTC)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Ripple (XRP)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Dogecoin (DOGE)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Polkadot (DOT)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Smartchain (BNB)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Dash (DASH)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>TRON (TRX)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Tezos (XTZ)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Cosmos (ATOM)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Stellar (XLM)</Link>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={ 4 } className='p-3'>
            <Link to='/user' className='border text-center p-3 nav-link rounded-4 shadow-sm h6'>Kava (KAVA)</Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Assets
