import React from 'react'
import {Container, Card, Carousel, CarouselItem, Row } from 'react-bootstrap';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'
const Testimonials = () =>
{
  const testimonies = [
    {
      name: 'Emily Johnson',
      testimony: `As both an NFT collector and a digital artist, this crypto NFT site has become my haven! The platform allows me to showcase and sell my artwork to a global audience, and the supportive community provides valuable feedback and encouragement. I appreciate the customizable profiles that let me express my artistic journey authentically. On the collector side, the vast array of NFTs available here never ceases to amaze me. I have found unique and rare pieces that are now cherished parts of my collection. The secure blockchain technology instills trust, and the platform's continuous improvements make it a top-notch NFT marketplace in my book!`
    },
    {
      name: 'Alexander Ramirez',
      testimony: `I have been using this crypto mining site for several months now, and I must say I am extremely impressed. The platform is user-friendly and straightforward to set up. The mining process is highly efficient, and I've been earning consistent profits. Customer support is top-notch; they promptly respond to any queries and provide valuable assistance. The variety of cryptocurrencies available for mining is extensive, which adds to the flexibility. I highly recommend this site to both beginners and experienced miners.`
    },
    {
      name: 'Sophia Patel',
      testimony: `I have a mixed experience with this crypto airdrop site. On the one hand, they offer a fair number of airdrops, and the process to claim tokens is relatively straightforward.However, I've noticed that some of the projects featured here lack transparency, and it's difficult to assess their long-term potential. Additionally, the site's user interface could use some improvement to make it more intuitive and visually appealing. On a positive note, I did receive tokens from a few airdrops, but it's important to remain cautious and do thorough research before participating in any of the offerings here.`
    },
    {
      name: 'Olivia Thompson',
      testimony: `I've tried multiple crypto investment platforms, but none have matched the convenience and efficiency of this one. The user interface is intuitive, making it easy to navigate and trade cryptocurrencies effortlessly. The auto-invest feature has been a game-changer for me, allowing me to set predefined strategies and let the platform handle the rest. Additionally, the mobile app ensures I can monitor my investments on the go. With competitive fees and an impressive selection of coins, this site has become my go-to platform for crypto investments..`
    },
  ]
  return (
    <Container fluid className='bg-dark text-light'>
      <Row>
        <p className="fs-4 fw-bold text-center my-3 text-uppercase">Testimonies</p>
        <Carousel variant='light'>
          { testimonies.map( testimony => (
            <CarouselItem  key={testimonies.indexOf(testimony)+1}>
              <Card className='w-100 mx-auto my-2 shadow bg-dark text-light'>
                <div className="">
                  <p className="h5 fw-bold text-capitalize p-3 m-1 ">{ testimony.name }</p>
                </div>
                <div className='px-4 mx-auto w-75'>
                  <FaQuoteLeft/>
                  <Card.Text className=' px-4'>
                    {testimony.testimony}
                  </Card.Text>
                  <FaQuoteRight className='float-end mb-3'/>
                </div>
              </Card>
            </CarouselItem>
          ))}
        </Carousel>
      </Row>
    </Container>
  )
}

export default Testimonials
