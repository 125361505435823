import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import img from '../../assets/img.png'
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="bg vh-100 text-light">
      <Container fluid className='bg-dark bg-opacity-50 h-100 center'>
        <Row className='w-100 mx-auto'>
          <Col  md={ 6} lg={8}  className='mx-auto d-none d-md-block my-auto'>
            <img src={ img } className='img-fluid' alt="" />
            <p className="h6 w-50 mx-auto">
              Effectively manage your digital assets, swap, sell (even take a little inspiration from the famous) all from within Defi server Connect
            </p>
          </Col>
          <Col xs={ 12 } md={ 6 } lg={4} className='mx-auto my-auto'>
            <h1>Link Defi Connect to mobile wallets</h1>
            <p className='h6'>Defi server connect is an open protocol that lets users interact with their mobile / web / ledger,  wallets to the web3 layer DApp  online Devs toolkit for fixes. Remember Non-custodial, You are in total control. End to End encryption for users safety. even though we don't collect any personal information. Your safety is our number One priority.</p>
            <div className='p-3 text-center d-flex '>
              <Link to='user' className=' btn btn-outline-danger my-auto text-capitalize fw-bold'>
                connect to wallet
              </Link>
              <Link to='service' className='btn btn-outline-danger  my-auto text-capitalize fw-bold mx-2'>Proceed</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hero
