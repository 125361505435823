import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

const Footer = () =>
{
  return (
    <footer className="bg-dark bg-opacity-75 text-light fixed-bottom py-2">
      <Container>
        <Row>
          <Col xs={ 8 } className='mx-auto'>
            <p className='text-center h5 text-uppercase'>Defi Connect</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
