import { Col, Container, Row } from 'react-bootstrap';

const Security = () => {
  return (
    <div className='vh-100 d-flex justify-content-center align-items-center bg-dark text-light'>
      <Container>
        <Row>
          <Col xs={ 10 } md={ 8 } className='mx-auto my-auto'>
            <img src='https://trustwallet.com/assets/images/home_security.png' alt='exchange' className='img-fluid w-100'/>
          </Col>
          <Col xs={ 10 } md={ 4 } className='my-auto mx-auto'>
            <h1 className="h1 text-center fw-bold text-capitalize">Private & Secure</h1>
            <p className=" fs-3 text-center">Only you can access your wallet. We don’t collect any personal data.</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Security
